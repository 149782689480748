<template>
  <v-container class="pa-0 ma-0" fluid>
    <v-row align="center">
      <v-col align="center" cols="12">
        <v-img src="../assets/1lifelogo.png" max-width="300px"> </v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="center">
        <h1>mySafetyMatters<br />Young Worker Safety Training</h1>
      </v-col>
    </v-row>
    <v-form ref="form" :disabled="errorMessage.length > 0">
      <v-row no-gutters align="end" justify="center">
        <v-col v-if="!showResult" align="center" :cols="isMobile ? 10 : 4">
          <v-row>
            <v-col>
              <v-text-field
                label="First name"
                background-color="white"
                v-model="user.firstName"
                color="msaBlue"
                filled
                depressed
                :style="{ 'border-radius': '5px' }"
                :rules="rules.firstName"
                :error-messages="errors.firstName"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                label="Last name"
                background-color="white"
                v-model="user.lastName"
                color="msaBlue"
                filled
                depressed
                :style="{ 'border-radius': '5px' }"
                :rules="rules.lastName"
                :error-messages="errors.lastName"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <v-text-field
                label="Email"
                background-color="white"
                v-model="user.email"
                color="msaBlue"
                filled
                depressed
                :style="{ 'border-radius': '5px' }"
                :rules="rules.email"
                :error-messages="errors.email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                label="Username"
                background-color="white"
                v-model="user.username"
                color="msaBlue"
                filled
                depressed
                :style="{ 'border-radius': '5px' }"
                :rules="rules.username"
                :error-messages="errors.username"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                label="Password"
                background-color="white"
                v-model="user.password"
                color="msaBlue"
                filled
                depressed
                :style="{ 'border-radius': '5px' }"
                :rules="rules.password"
                :error-messages="errors.password"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="errorMessage.length">
            <v-col>
              <span class="red--text">{{ errorMessage }}</span>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-checkbox
                v-model="user.isInAgreement"
                :rules="rules.isInAgreement"
                :error-messages="errors.isInAgreement"
              >
                <template v-slot:label>
                  <div>
                    I agree with
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a
                          target="_blank"
                          href="https://1lifesoftware.ca/privacy-policy/"
                          @click.stop
                          v-on="on"
                        >
                          privacy policy
                        </a>
                      </template>
                      opens in new window
                    </v-tooltip>
                  </div>
                </template>
              </v-checkbox></v-col
            >
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-checkbox
                v-model="user.isRequiredAge"
                label="I certify that I am 13 years of age or older"
                color="msaBlue"
                :rules="rules.isRequiredAge"
                :error-messages="errors.isRequiredAge"
              >
              </v-checkbox
            ></v-col>
          </v-row>
          <v-row class="mt-n2">
            <v-col>
              <v-btn
                class="green white--text"
                :disabled="errorMessage.length > 0"
                @click="validateForm"
                block
              >
                Sign Up
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-else align="center">
          <v-row align="center">
            <v-col
              ><v-alert
                border="top"
                color="white"
                dark
                class="text-subtitle-1 grey--text text--darken-2"
                max-width="650px"
              >
                <p>You have successfully signed up.</p>
                <p>Your credentials are:</p>
                <p>
                  Username: {{ result.username }}<br />
                  Password:
                  {{ result.password }}
                </p>
                <p>
                  Use this link to access:
                  <a :href="result.url">1Life Software</a>
                </p>
                <p>
                  A copy of your credentials has been sent to your email address
                  {{ user.email }}.
                </p>
              </v-alert></v-col
            >
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
export default {
  name: 'YoungWorkerSignUpPage',
  data() {
    return {
      showPassword: false,
      user: {
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        password: '',
        isInAgreement: false,
        isRequiredAge: false,
      },
      rules: {
        email: [(v) => this.validateEmail(v)],
        username: [(v) => this.validateUsername(v)],
        firstName: [(v) => this.validateFirstName(v)],
        lastName: [(v) => this.validateLastName(v)],
        password: [(v) => this.validatePassword(v)],
        isInAgreement: [(v) => this.validateAgrement(v)],
        isRequiredAge: [(v) => this.validateAge(v)],
      },
      errors: {
        email: [],
        username: [],
        firstName: [],
        lastName: [],
        password: [],
        isInAgreement: [],
        isRequiredAge: [],
        error: [],
      },
      result: {
        username: '',
        password: '',
        url: '',
      },
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    showResult() {
      return this.result.url.length > 0;
    },
    errorMessage() {
      var result = '';
      if (this.errors.error.length > 0) {
        result = this.errors.error[0];
      }
      return result;
    },
  },
  methods: {
    validateEmail(email) {
      this.errors.email = [];
      var result = this.$helpers.validateEmail(email, true);
      if (result == 'Email is required for this account type.') {
        return 'Email is required.';
      }
      return this.$helpers.validateEmail(email, true);
    },
    validateUsername(username) {
      this.errors.username = [];
      return this.$helpers.validateUsername(username);
    },
    validateFirstName(name) {
      this.errors.firstName = [];
      return this.$helpers.required(name, 'First name');
    },
    validateLastName(name) {
      this.errors.lastName = [];
      return this.$helpers.required(name, 'Last name');
    },
    validatePassword(password) {
      this.errors.password = [];
      return this.$helpers.validatePassword(password);
    },
    validateAgrement(value) {
      this.errors.isInAgreement = [];
      return this.$helpers.required(value, 'Privacy policy agreement');
    },
    validateAge(value) {
      this.errors.isRequiredAge = [];
      return this.$helpers.required(value, 'Age certification');
    },
    validateForm() {
      if (this.$refs.form.validate()) {
        this.signUp();
      }
    },
    resetErrors() {
      this.errors.email = [];
      this.errors.username = [];
      this.errors.firstName = [];
      this.errors.lastName = [];
      this.errors.title = [];
      this.errors.error = [];
    },
    handleErrors(errors) {
      if (!Array.isArray(errors)) {
        return;
      }
      errors.forEach((error) => {
        this.errors[error.property].push(error.message);
      });
    },
    signUp() {
      const url = 'public/young-worker-sign-up?format=json';
      const params = {
        disableAutoError: true,
        user: this.user,
        loaderText: 'Signing up...',
      };
      this.resetErrors();
      this.$axios
        .post(url, params)
        .then((response) => {
          this.result = response.data;
        })
        .catch((error) => {
          this.handleErrors(error.response.data);
        });
    },
  },
};
</script>
